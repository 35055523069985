<template>
  <v-card>
    <div class="d-flex flex-no-wrap justify-space-between">
      <div>
        <v-avatar
            class="ma-3"
            width="150"
            size="125"
            tile>
          <v-img :src="this.image_url+travel.image"/>
        </v-avatar>
        <v-alert
            color="#d81d86"
            dark
            width="150"
            class="ml-3 mt-n3 travelType"
            :icon="getTravelIcon()"
            border="left">

          {{getTravelTypus()}}

        </v-alert>
        <v-card-actions class="ml-3">
          <p class="mr-1 shareText">Compartir:</p>
          <ShareNetwork
              class="mr-1 mt-n3"
              network="facebook"
              title=""
              :url="env_url+'/viaje/'+travel.ref"
              :quote="$ml.get('travelCard.shareMessage') ">
            <i class="rrssIcon fab fa-facebook-f"/>
          </ShareNetwork>
          <ShareNetwork
              network="twitter"
              class="mr-1 mt-n3"
              :url="env_url+'/viaje/'+travel.ref"
              :title="$ml.get('travelCard.shareMessage') ">
            <i class="rrssIcon fab fa-twitter"></i>
          </ShareNetwork>
          <ShareNetwork
              class=" mt-n3"
              network="whatsapp"
              :url="env_url+'/viaje/'+travel.ref"
              :title="$ml.get('travelCard.shareMessage') ">
            <i class="rrssIcon fab fa-whatsapp"/>
          </ShareNetwork>
        </v-card-actions>
      </div>
      <div>
        <a :href="env_url+'/viaje/'+travel.ref" rel="nofollow noopener" target="_blank">
          <v-card-title class="titleCard" v-if="lang==='1'" v-text="travel.titleES"/>
          <v-card-title class="titleCard" v-else v-text="travel.titleCAT"/>
        </a>
        <v-card-subtitle class="subtitleTravel">
          <div class=" subtitle-1">
            <i class="far fa-calendar-alt"/> {{ date }}
          </div>
          <div class=" subtitle-1" v-if=" travel.duration==='1'">
            <i class="far fa-clock"/> {{ travel.duration }} <span v-if="lang==='1'">día</span>
            <span v-else>dia</span>
          </div>
          <div class=" subtitle-1" v-else>
            <i class="far fa-clock"/> {{ travel.duration }} <span v-if="lang==='1'">días</span>
            <span v-else>dies</span>
          </div>
          <div class="subtitle-1">
            <i class="fas fa-wallet"/> {{ travel.price }} €
          </div>
        </v-card-subtitle>
        <v-card-actions>
          <a v-if="quantity>0" :href="env_url+'/viaje/'+travel.ref" rel="nofollow noopener" target="_blank">

          <v-btn v-if="quantity>0" class="reserButton ml-3" text
                 v-text="$ml.get('travelCard.reservar')"/>
          </a>
          <v-btn v-else disabled style="color:white" text v-text="$ml.get('travelCard.outOfStock')"/>
        </v-card-actions>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "iframeMobileCard",
  props: ['travel','lang'],
  data: () => ({
    loading: false,
    height: 625,
    quantity: 0,
    date: ''
  }),
  mounted() {
    this.quantity = (parseInt(this.travel.places) - parseInt(this.travel.reservations));
    if (this.quantity <= 10 && this.quantity > 0) this.height = 575
    else this.height = 475
    const splitDate = this.travel.date.split('-');
    this.date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
  },
  methods: {
    getTravelTypus(){
      switch (this.travel.typus){
        case '1':
          return 'Bus';
        case '2':
          return 'Bus + Hotel';
        case '3':
          return 'Viaje club';
      }
    },
    getTravelIcon(){
      switch (this.travel.typus){
        case '1':
          return 'mdi-bus';
        case '2':
          return 'mdi-bed';
        case '3':
          return 'mdi-credit-card-outline';
      }
    }
  }
}
</script>

<style scoped>
.rrssIcon {
  color: white !important;
}

.titleCard {
  font-size: 13pt;
  word-break: keep-all;
  line-height: 18px;
  margin-bottom: 1em;
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 50px;
}
.subtitleTravel{
  margin-top: -25px ;
}

.reserButton {
  width: 90%;
  color: #d81d86 !important;
  border: 1px solid #d81d86;
}

.rrssIcon {
  color: #d81d86 !important;
}

.shareText {
  color: #d81d86 !important;
  font-weight: bold;
}

.travelType {
  padding: 3px;
}
</style>