<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="12" align-self="center">
      <v-row v-if="!loaded">
        <v-col cols="12" md="3" v-for="i in 4" v-bind:key="i">
          <v-skeleton-loader type="card-avatar, article, actions"/>
        </v-col>
      </v-row>
      <v-row v-else-if="loaded && !isMobile()">
        <v-col cols="12" md="3" v-for="travel in travels" v-bind:key="travel.ref">
          <iframe-travel-card :travel="travel" :lang="lang"/>
        </v-col>
      </v-row>
      <v-row v-else-if="loaded && isMobile()">
        <v-col cols="12" md="12" v-for="travel in travels" v-bind:key="travel.ref">
          <iframe-mobile-card :travel="travel" :lang="lang"/>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
import iframeTravelCard from "../components/home/partials/iframeTravelCard";
import IframeMobileCard from "../components/home/partials/iframeMobileCard";

export default {
  name: 'Home',
  components: {IframeMobileCard, iframeTravelCard},
  data: () => ({
    travels: [],
    loaded: false,
    lang:1
  }),
  mounted() {
    this.getData()
    this.refreshUnconfirmedReservations()
  },
  methods: {
    refreshUnconfirmedReservations() {
      let url = this.base_url + "/admin/borrarpendientes";
      this.axios.get(url).then(() => {
      })
    },
    getData() {
     this.lang = this.$route.params.lang
      let url = this.base_url + "/client/iframeviajes/"+this.lang;
      console.log(url)
      this.axios.get(url).then(response => {
        this.travels = response.data.travels;
        this.loaded = true;
      });
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.newsTitle {
  padding: 10px 10px 10px 10px;
  background-color: #d81d86;
  font-family: "Playfair Display", Sans-serif;
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
}

.newsSubtitle {
  color: #767676;
  font-family: "Montserrat", Sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2em;
  letter-spacing: 1px;
  text-align: center;
}

.checkCol {
  margin-top: -2em;
}

.checkColBT {
  margin-top: -1em;
}

.footBT {
  margin: 0 auto;
  display: block ruby;
}

.nextTravels {
  color: #d81d86;
  font-family: "Playfair Display", Sans-serif;
  font-size: 2em;
  font-weight: normal;
  text-transform: none;
}
</style>
