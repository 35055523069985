<template>
  <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
      :height="height">
    <a :href="env_url+'/viaje/'+travel.ref" rel="nofollow noopener" target="_blank">
      <v-img height="200"
             :src="this.image_url+travel.image"/>
      <v-card-title>
        <h3 class="titleCard" v-if="lang==='1'">{{ travel.titleES }} </h3>
        <h3 class="titleCard" v-else>{{ travel.titleCAT }}</h3>
      </v-card-title>
    </a>
    <v-card-text class="subtitleTravel">
      <div class="my-4 subtitle-1">
        <i class="far fa-calendar-alt"/> {{ date }}
      </div>
      <div class="my-4 subtitle-1" v-if=" travel.duration==='1'">
        <i class="far fa-clock"/> {{ travel.duration }} <span v-if="lang==='1'">día</span>
        <span v-else>dia</span>
      </div>
      <div class="my-4 subtitle-1" v-else>
        <i class="far fa-clock"/> {{ travel.duration }} <span v-if="lang==='1'">días</span>
        <span v-else>dies</span>
      </div>
      <div class="my-4 subtitle-1">
        <i class="fas fa-wallet"/> {{ travel.price }} €
      </div>
      <div class="my-4 subtitle-1">
        <v-alert
            v-if="quantity<10  && quantity>0"
            dense
            outlined
            color="#b52787"
            type="error" v-text="$ml.get('travelCard.lastSeats')"/>
      </div>
    </v-card-text>
    <v-card-actions class="footerCard" v-bind:class="{disabled : quantity<=0}">
      <a v-if="quantity>0" :href="env_url+'/viaje/'+travel.ref" rel="nofollow noopener" target="_blank">

        <v-btn light v-if="quantity>0" class="reserButton" color="#d81d86"
               v-text="$ml.get('travelCard.reservar')"/>
      </a>
      <v-btn v-else disabled style="color:white" text v-text="$ml.get('travelCard.outOfStock')"/>
      <v-spacer></v-spacer>
      <ShareNetwork
          class="mr-1"
          network="facebook"
          title=""
          :url="env_url+'/viaje/'+travel.ref"
          :quote="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-facebook-f"/>
      </ShareNetwork>
      <ShareNetwork
          network="twitter"
          class="mr-1"
          :url="env_url+'/viaje/'+travel.ref"
          :title="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-twitter"></i>
      </ShareNetwork>
      <ShareNetwork
          network="whatsapp"
          :url="env_url+'/viaje/'+travel.ref"
          :title="$ml.get('travelCard.shareMessage') ">
        <i class="rrssIcon fab fa-whatsapp"/>
      </ShareNetwork>
    </v-card-actions>
  </v-card>
</template>


<script>
export default {
  name: "iframeTravelCard",
  props: ['travel', 'lang'],
  data: () => ({
    loading: false,
    height: 625,
    quantity: 0,
    date: '',
    tbt: 'a',
  }),
  mounted() {
    this.quantity = (parseInt(this.travel.places) - parseInt(this.travel.reservations));
    if (this.quantity <= 10 && this.quantity > 0) this.height = 600
    else this.height = 500
    const splitDate = this.travel.date.split('-');
    this.date = splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
    if (this.lang === 2) this.sharePhrase = 'Mira aquest viatge que vaig trobar en kariba: '
  },
  calculateHeight() {
    if (this.isMobile()) return '32em';
    else return '30em';
  },
  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true
    } else {
      return false
    }
  }
}
</script>


<style scoped>
.titleCard {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 55px;
  z-index: 1;
}

.subtitleTravel {
  margin-top: -25px;
}

.rrssIcon {
  color: #d81d86 !important;
}

.footerCard {
  bottom: 0;
  position: absolute;
  width: 100%;
  border-top: 2px solid #d81d86;
  color: white !important;
}

.reserButton {
  width: 130px;
}

.subtitle-1 {
  font-size: 1.15rem !important
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  .titleCard {
    font-size: 11pt;

  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  .titleCard {
    font-size: 11pt;

  }

  .reserButton {
    width: 100px;
  }
}
</style>